<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3 row">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanRepayment.loanNumber")
                    }}</label>
                    <Input
                        v-model="model.loan_number"
                        style="width: 100%"
                        :class="{
                            'ivu-form-item-error is-invalid': errors.has('loan_number')
                        }"
                    ></Input>
                    <div
                        v-if="errors.has('loan_number')"
                        class="ivu-form-item-error tw-text-red-600"
                    >
                        {{ errors.first("loan_number") }}
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "loanRepaymentForm",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            model: {
                loan_number: null
            }
        };
    },
    methods: {
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "creditOperation/loanRepayment/restoreLoanPaidOff",
                    this.model
                )
                .then(response => {
                    this.clearInput();
                    this.notice({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.model.loan_number = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LAON REPAYMENT",
                desc: not.text
            });
        }
    }
};
</script>
